export const portugueseLinks = [
  {
    label: "Home",
    url: "/",
  },

  {
    label: "Sobre",
    url: "/about",
  },
]
export const englishLinks = [
  {
    label: "Home",
    url: "/",
  },

  {
    label: "About",
    url: "/about",
  },
]
